/* Reset */
html,
body,
div {
  border: 0;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Box-sizing border-box */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Set up a default font */
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #3c3c3c;
  background: #93939333;
}

.wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.wide-wrapper {
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.loading {
  width: 100px;
  text-align: center;
  margin: 100px auto;
}

p {
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 20px;
  margin-bottom: 1em;
  margin-top: 1em;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

a.underline {
  text-decoration: underline;
}

a.blue {
  color: #365899;
}

.icon-button {
  margin-right: 8px;
}

/* FORMS */
.hint {
  color: rgba(0, 0, 0, 0.6);
  font-size: 75%;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin-bottom: 15px;
}

.error-field {
  font-size: 75%;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em);
  display: inline-block;
  position: relative;
  color: #f44336;
  margin-left: 12px;
}

.wrap-mat-checkbox-label {
  white-space: normal;
}

/* HELPERS */
.fill-spacer {
  flex: 1 1 auto;
}

.full-width {
  width: 100%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.margin-top {
  margin-top: 30px !important;
}

.margin-bottom {
  margin-bottom: 30px !important;
}

.bold {
  font-weight: 500 !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.uppercase {
  text-transform: uppercase !important;
}

/* CONTENT PAGES */
.page {
  padding: 40px;
  background: white;
}

.page li {
  margin-top: 7px;
}

.response {
  text-align: center;
  padding: 20px;
  border-radius: 3px;
}

.response.success {
  background: #dfffdf;
  border: 1px solid green;
}

.response.error {
  background: #ffebeb;
  border: 1px solid red;
}

//TO BE DELETED - VMFEST
/* In your component's CSS file (e.g., component.component.css) */
@font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.vmfest-background-violet {
  background: #8031a7;
}

.vmfest-color-violet {
  color: #8031a7;
}

.vmfest-color-pink {
  color: #ef60a3;
}

.vmfest-color-orange {
  color: #ff6a14;
}

.vmfest-background-violet {
  background: #8031a7;
}

.vmfest-color-violet {
  color: #8031a7;
}

@media (max-width: 768px) {
  .page {
    margin: 0;
  }

  .wrapper,
  .wide-wrapper {
    max-width: 750px;
  }
}

@media (max-width: 1024px) {
  .page {
    padding: 20px;
  }

  .wrapper,
  .wide-wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 599px) {
  .page {
    margin: 0;
    padding: 20px;
  }

  .wrapper{
    padding-left: 10px;
    padding-right: 10px;
  }

  .wide-wrapper {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
  }
}