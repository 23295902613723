/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming'; // Plus imports for other components in your app.
// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat-core(); // define a real custom palette (using http://mcg.mbitson.com)
$primary-color: (
  50 : #e8edee,
  100 : #c5d1d4,
  200 : #9eb3b7,
  300 : #77959a,
  400 : #597e85,
  500 : #3c676f,
  600 : #365f67,
  700 : #2e545c,
  800 : #274a52,
  900 : #1a3940,
  A100 : #81e5ff,
  A200 : #4edbff,
  A400 : #1bd0ff,
  A700 : #02cbff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$secondary-color: (
  50 : #e9ebe5,
  100 : #c9cebd,
  200 : #a5ad92,
  300 : #808c66,
  400 : #657345,
  500 : #4a5a24,
  600 : #435220,
  700 : #3a481b,
  800 : #323f16,
  900 : #222e0d,
  A100 : #c6ff6b,
  A200 : #b2ff38,
  A400 : #9fff05,
  A700 : #90eb00,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$custom-primary: mat-palette($primary-color);
$custom-accent: mat-palette($secondary-color); // The warn palette is optional (defaults to red).
$custom-warn: mat-palette($mat-red); // Create the theme object (a Sass map containing all of the palettes).
$custom-theme: mat-light-theme($custom-primary, $custom-accent, $custom-warn); // Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($custom-theme);

.background-primary {
  background: mat-color($custom-primary);
}

.color-primary {
  color: mat-color($custom-primary);
}

.background-accent {
  background: mat-color($custom-accent);
}

.warning-message {
  background: mat-color($custom-warn);
}

.third-color {
  color: #f0284a;
}

.third-background {
  background: black;
}